import React from 'react';
import '../App.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <p>&copy; {new Date().getFullYear()} Grupo DAVSA. Todos los derechos reservados.</p>
        <a href="/AvisoDePrivacidad.pdf" target="_blank" rel="noopener noreferrer">
            Aviso de privacidad.
          </a>
      </div>
    </footer>
  );
}

export default Footer;
