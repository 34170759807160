import React, { useState } from 'react';
import im1 from '../images/LogoDAVSA.png';
import '../App.css';
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import Image1 from '../images/Image1.jpg';
import Image2 from '../images/Image2.jpg';
import Image3 from '../images/Image3.jpg';
import Image4 from '../images/Image4.jpg';
import Image6 from '../images/Image6.jpg';
import Image7 from '../images/Image7.jpg';
import Image8 from '../images/Image8.jpg';
import Image9 from '../images/Image9.jpg';
import Image11 from '../images/Image11.jpg';
import Image12 from '../images/Image12.jpg';
import Image13 from '../images/Image13.jpg';
import Image14 from '../images/Image14.jpg';
import Image15 from '../images/Image15.jpg';
import Image16 from '../images/Image16.jpg';
import Image17 from '../images/Amanac1.jpeg';
import Image18 from '../images/Amanac2.jpeg';
import Image19 from '../images/Amanac3.jpeg';
import Image20 from '../images/Amanac4.jpeg';
import Image21 from '../images/Amanac5.jpeg';



function Home() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage(null);
  };

  return (
    <div className="App">
        <div className="main-container">
            <div className="image-container">
            <img src={im1} className="App-logo" alt="logo" />
            </div>
            <div className="image-gallery-container">
            <div className="image-gallery">
                <h2>Nuestro trabajo:</h2>
                <img src={Image1} alt="Imagen 1" onClick={() => openModal(Image1)} />
                <img src={Image2} alt="Imagen 2" onClick={() => openModal(Image2)} />
                <img src={Image3} alt="Imagen 3" onClick={() => openModal(Image3)} />
                <img src={Image4} alt="Imagen 4" onClick={() => openModal(Image4)} />
                <img src={Image6} alt="Imagen 6" onClick={() => openModal(Image6)} />
                <img src={Image7} alt="Imagen 7" onClick={() => openModal(Image7)} />
                <img src={Image8} alt="Imagen 8" onClick={() => openModal(Image8)} />
                <img src={Image9} alt="Imagen 9" onClick={() => openModal(Image9)} />
                <img src={Image11} alt="Imagen 11" onClick={() => openModal(Image11)} />
                <img src={Image12} alt="Imagen 12" onClick={() => openModal(Image12)} />
                <img src={Image13} alt="Imagen 13" onClick={() => openModal(Image13)} />
                <img src={Image14} alt="Imagen 14" onClick={() => openModal(Image14)} />
                <img src={Image15} alt="Imagen 15" onClick={() => openModal(Image15)} />
                <img src={Image16} alt="Imagen 16" onClick={() => openModal(Image16)} />
                <img src={Image17} alt="Imagen 17" onClick={() => openModal(Image17)} />
                <img src={Image18} alt="Imagen 18" onClick={() => openModal(Image18)} />
                <img src={Image19} alt="Imagen 19" onClick={() => openModal(Image19)} />
                <img src={Image20} alt="Imagen 20" onClick={() => openModal(Image20)} />
                <img src={Image21} alt="Imagen 21" onClick={() => openModal(Image21)} />
            </div>
            </div>
        </div>
            <div className="text-container">
            <h1>Especialistas en la logística de eventos empresariales.</h1>
            <p>
            Somos un grupo de empresarios dedicados a satisfacer todas las necesidades logísticas de su evento, desde la planeación inicial hasta su conclusión exitosa.<br/></p>
            
            <p>Contamos con amplia experiencia en el sector financiero y bancario, lo que nos permite ofrecer soluciones sólidas y de confianza. <br/></p>
            <p>Ofrecemos una variedad de productos integrales para su empresa o negocio, incluyendo artículos promocionales, accesorios personalizados con logotipo, y textiles con bordado y serigrafía.<br/></p>

            <p>Nuestras alianzas estratégicas nos permiten brindar precios competitivos y soluciones completas, asegurando siempre la mejor calidad en cada proyecto.</p>
            
            <div className="contacto-container">
            <p><h2><b>Contacto:</b></h2>
                <FaPhone /> &nbsp; <b> 55-56-61-69-51</b><br/>
                <IoMdMail /> &nbsp; <b> a.sanjuan@grupodavsa.com</b>
            </p>
            </div>

            {isModalOpen && (
                <div className="modal" onClick={closeModal}>
                <span className="close">&times;</span>
                <img src={currentImage} alt="Vista ampliada" className="modal-content" />
                </div>
            )}

            </div>
    </div>
  );
}

export default Home;