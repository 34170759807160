import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './componentes/Header';
import Footer from './componentes/Footer';
import Home from './paginas/Home';
import Servicios from './paginas/Servicios';
import Clientes from './paginas/Clientes';
import Impresiones from './paginas/Impresiones';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/impresiones" element={<Impresiones />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;