import React from 'react';
import '../App.css'; 
import cliente1 from '../images/LogoADQA.png';
import cliente2 from '../images/LogoAMANAC.png';
import cliente3 from '../images/LogoAMSTAR.png';
import cliente4 from '../images/LogoANTP.png';
import cliente5 from '../images/LogoBBVA.png';
import cliente6 from '../images/LogoCANACAR.png';
import cliente7 from '../images/LogoCANALUM.png';
import cliente8 from '../images/LogoCANAME.png';
import cliente9 from '../images/LogoCCC.png';
import cliente10 from '../images/LogoCMIC.png';
import cliente11 from '../images/LogoCONACCA.png';
import cliente12 from '../images/LogoCONCAMIN.png';
import cliente13 from '../images/LogoENOMINA.png';
import cliente14 from '../images/LogoGrupo.png';
import cliente15 from '../images/LogoINTER.png';
import cliente16 from '../images/LogoSTPS.png';
import cliente17 from '../images/LogoTHREE.jpg';
import cliente18 from '../images/LogoTM.png';
import cliente19 from '../images/LogoVITA.png';

function Clientes() {
    return (
        <div className="clientes">
            <h1>Nuestros Clientes</h1>
            <p><b>En Grupo DAVSA, estamos orgullosos de trabajar con clientes de diversas industrias y sectores.</b></p>

            <div className="clientes__lista">
                <div className="cliente">
                    <img src={cliente1} alt="Cliente 1" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente2} alt="Cliente 2" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente3} alt="Cliente 3" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente4} alt="Cliente 4" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente5} alt="Cliente 5" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente6} alt="Cliente 6" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente7} alt="Cliente 7" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente8} alt="Cliente 8" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente9} alt="Cliente 9" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente10} alt="Cliente 10" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente11} alt="Cliente 11" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente12} alt="Cliente 12" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente13} alt="Cliente 13" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente14} alt="Cliente 14" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente15} alt="Cliente 15" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente16} alt="Cliente 16" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente17} alt="Cliente 17" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente18} alt="Cliente 18" className="cliente__imagen"/>
                </div>
                <div className="cliente">
                    <img src={cliente19} alt="Cliente 19" className="cliente__imagen"/>
                </div>
            </div>
        </div>
    );
}

export default Clientes;