import React from 'react';
import '../App.css'; // Asegúrate de crear el archivo de estilos si deseas agregar estilos personalizados

function Servicios() {
  return (
    <div className="servicios">
      <h1>Nuestros Servicios</h1>

      <p>En Grupo DAVSA ofrecemos una variedad de servicios para satisfacer las necesidades de nuestros clientes.</p>

      <p>Nos especializamos en la organización, operación y comercialización de eventos, ferias, exposiciones, promociones, exhibiciones, convenciones, congresos, seminarios, banquetes, presentaciones de ventas y cualquier otra actividad relacionada con la publicidad y comercialización de productos y servicios.</p>
      
      <div className="servicios__lista">
        <div className="servicio">
          <p>Brindamos asesoría y soporte integral para todo tipo de crédito, facilitando el acceso a recursos necesarios a través de la institución más adecuada.</p>
        </div>
        <div className="servicio">
          <p>Promocionamos productos financieros altamente especializados para la obtención de recursos económicos que potencien el desarrollo de las empresas, mediante estrategias personalizadas adaptadas a las necesidades y conveniencia del cliente.</p>
        </div>
        <div className="servicio">
          <p>Ofrecemos una amplia gama de productos, incluyendo artículos de limpieza, papelería y todo lo necesario para satisfacer los requerimientos de su oficina y/o negocio.</p>
        </div>
      </div>

      <div className="que-ofrecemos">
          <h2>Qué ofrecemos:</h2>
          <div className="two-columns">
          <ul>
            <li>Contratación de Conferencistas (Deportistas, Políticos, Artistas, Cantantes).</li>
            <li>Contrataciones de: Hoteles, Restaurantes, Recintos feriales, etc.</li>
            <li>Coordinación de Actividades.</li>
            <li>Diseño, Renta y Venta de Stands.</li>
            <li>Diseños Gráficos.</li>
            <li>Escenografías.</li>
            <li>Estadísticas de participación en eventos.</li>
            <li>Fiestas Tema.</li>
            <li>Iluminación y ambientación.</li>
            <li>Invitaciones, Telemarketing.</li>
            <li>Promocionales.</li>
            <li>Registro.</li>
            <li>Renta de equipo: Audio, Videoproyección, Plasmas, Pantallas de leds, etc.</li>
            <li>Traducción simultanea.</li>
            <li>Transportación foránea y local.</li>
            <li>Administración de personal.</li>
            <li>Contratación de personal.</li>
            <li>Crédito Hipotecario.</li>
            <li>Elaboración y pago de nómina.</li>
            <li>Financiamiento a corto, mediano y largo plazo.</li>
            <li>Maquila de Nómina.</li>
            <li>Operadora de Back Office.</li>
            <li>Prestación de servicios de reclutamiento.</li>
            <li>Reclutamiento y selección de personal.</li>
            <li>Reestructuras.</li>
            <li>Tarjetas de Crédito.</li>
          </ul>
          </div>
        </div>
    </div>
  );
}

export default Servicios;