import React, { useState } from 'react';
import '../App.css'; // Asegúrate de crear este archivo para estilos específicos de esta página
import impresora3DImg from '../images/Impresora.png';
import blender from '../images/BlenderFigura.jpg';
import producto from '../images/Producto.jpg';
import barco from '../images/Barco.jpeg';
import dino1 from '../images/Dino1.jpeg';
import dino2 from '../images/Dino2.jpeg';
import dino3 from '../images/Dino3.jpeg';
import ele1 from '../images/Ele1.jpeg';
import ele2 from '../images/Ele2.jpeg';
import ele3 from '../images/Ele3.jpeg';
import perro1 from '../images/Perro1.jpeg';
import perro2 from '../images/Perro2.jpeg';
import pulpo from '../images/Pulpo.jpeg';
import aud1 from '../images/Audifonos1.jpg';
import aud2 from '../images/Audifonos2.jpg';

function Impresiones() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const openModal = (image) => {
        setCurrentImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentImage(null);
    };

    return (
        <div className="impresiones3D">
            <h1>Impresiones 3D Personalizadas</h1>
            <p>
                Ofrecemos servicios de impresiones 3D de alta calidad utilizando la impresoras de <strong>alto rendimiento</strong> y software de modelado como <strong>Blender</strong>.
            </p>
            
            <div className="impresiones3D__contenido">

                <div className="image-gallery-container">
                <div className="image-gallery">
                    <img src={blender} alt="Figura Blender" onClick={() => openModal(blender)} />
                    <img src={producto} alt="Producto Finalizado" onClick={() => openModal(producto)} />
                    <img src={barco} alt="Figura Barco" onClick={() => openModal(barco)} />
                    <img src={dino1} alt="Figura Dino1" onClick={() => openModal(dino1)} />
                    <img src={dino2} alt="Figura Dino2" onClick={() => openModal(dino2)} />
                    <img src={dino3} alt="Figura Dino3" onClick={() => openModal(dino3)} />
                    <img src={ele1} alt="Figura Elefante" onClick={() => openModal(ele1)} />
                    <img src={ele2} alt="Figura Elefante2" onClick={() => openModal(ele2)} />
                    <img src={ele3} alt="Figura Elefante3" onClick={() => openModal(ele3)} />
                    <img src={perro1} alt="Figura Perro1" onClick={() => openModal(perro1)} />
                    <img src={perro2} alt="Figura Perro2" onClick={() => openModal(perro2)} />
                    <img src={pulpo} alt="Figura Pulpo" onClick={() => openModal(pulpo)} />
                    <img src={aud1} alt="Figura Audifonos1" onClick={() => openModal(aud1)} />
                    <img src={aud2} alt="Figura Audifonos2" onClick={() => openModal(aud2)} />


                </div>
                </div>

                <div className="impresiones3D__imagen">
                    <img src={impresora3DImg} alt="Impresora 3D Bambu Lab P1S" />
                </div>

                <div className="impresiones3D__texto">
                    <h2>Nuestra Tecnología</h2>
                    <p>
                        <strong>Nuestras impresoras</strong> nos permiten crear piezas con precisión y durabilidad, 
                        ideales para prototipos, piezas personalizadas y objetos decorativos. Con <strong>Blender</strong>, podemos 
                        diseñar modelos 3D personalizados para adaptarnos a tus necesidades exactas.
                    </p>
                    
                    <h2>Aplicaciones</h2>
                    <ul>
                        <li>Prototipos de productos</li>
                        <li>Piezas personalizadas</li>
                        <li>Modelos decorativos</li>
                        <li>Soportes técnicos</li>
                    </ul>
                </div>

                {isModalOpen && (
                    <div className="modal" onClick={closeModal}>
                    <span className="close">&times;</span>
                    <img src={currentImage} alt="Vista ampliada" className="modal-content" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Impresiones;