import React from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import '../App.css';
import im1 from '../images/LogoDAVSA.png';

function Header() {
  return (
    <header className="header">
      <div className="header__logo">
        <img src={im1} alt="Company Logo" />
      </div>
      <nav className="header__nav">
        <ul>
          <li><Link to="/">Nosotros</Link></li>
          <li><Link to="/servicios">Servicios</Link></li>
          <li><Link to="/clientes">Clientes</Link></li>
          <li><Link to="/impresiones">Impresiones 3D</Link></li>
        </ul>
      </nav>
      <div className="header__cta">
        <a href="https://wa.me/525526537675" className="cta-button">
          <FaWhatsapp className="whatsapp-icon" style={{ marginRight: '4px' }} /> 
          Escríbenos
        </a>
      </div>
    </header>
  );
}

export default Header;